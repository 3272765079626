
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_STATE, DISCUSS_TOP_MOVEMENT } from '@/services/discussService';
import { timestampToTimeAgo } from '@/filters/timestampToString';
import { translate } from '@/filters/translate';
import { errorToString } from '@/filters/content-filters';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import { dialogService } from '@/services/dialogService';
import Editor from '@tinymce/tinymce-vue';
import BoardReply from './BoardReply.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { AlertDialogController } from '../../../components/dialogs/DialogProps';
import { IArticle } from '@/vo/Discuss';
import { ITeam, ITeamship, IUser } from '@/vo/User';
import { TeamshipSetting } from '../../../datas/FriendshipStatus';

@Component({
    methods: {
        timestampToTimeAgo,
        translate,
        getUserRoute,
        getUserTooltip,
    },
    components: {
        Editor,
        BoardReply,
        CssPreloader,
    },
})
export default class BoardFooter extends Vue {
    @Prop()
    article!: IArticle;

    @Prop()
    user!: IUser;

    @Prop()
    isRoot!: boolean;

    @Prop()
    team?: ITeam;

    @Prop()
    teamship?: ITeamship;

    @Prop({ default: false })
    showReplyButton!: boolean;

    @Prop({ default: false })
    repliesShown!: boolean;

    @Prop()
    isPrivateToMe!: boolean;

    @Emit('loadMoreReplies')
    loadMoreReplies(): void {}

    @Emit('refresh')
    refresh(): void {}

    @Emit('stateChange')
    stateChange(article: IArticle): IArticle {
        return article;
    }

    manageIcons: { [key: string]: string } = {
        open: 'fas fa-broadcast-tower',
        close: 'fas fa-eye-slash',
        dialog: 'fas fa-comments',
        removed: 'fas fa-trash',
        markTop: 'fas fa-level-up-alt',
        cancelTop: 'fas fa-level-down-alt',
    };

    get category(): DISCUSS_CATEGORY {
        return DISCUSS_CATEGORY.USER_BOARD;
    }

    get removed(): boolean {
        return this.articleState == DISCUSS_STATE.REMOVED;
    }

    get articleState(): DISCUSS_STATE {
        return DISCUSS_STATE.getByCode(this.article.state);
    }

    get isManager(): boolean {
        if (this.team) {
            return TeamshipSetting.checkAuth(this.teamship, TeamshipSetting.MANAGE);
        }
        return serverService.isMe(this.user);
    }

    get manage_actions(): string[] {
        let actions: string[] = [];
        let state = this.articleState;
        if (state != DISCUSS_STATE.OPEN) {
            actions.push('open');
        }
        if (this.isRoot) {
            if (state != DISCUSS_STATE.DIALOG) {
                actions.push('dialog');
            }
            if (state != DISCUSS_STATE.CLOSE) {
                actions.push('close');
            }
            if (this.article.top) {
                actions.push('cancelTop');
            } else {
                actions.push('markTop');
            }
        }
        if (state != DISCUSS_STATE.REMOVED) {
            actions.push('removed');
        }
        return actions;
    }

    getMemberTooltip(teamMeta: { [key: string]: any }): string {
        const settings = teamMeta.settings;
        let leader = settings == TeamshipSetting.LEADER;
        let tip = translate(leader ? 'label.team.leader' : 'label.team.member');
        if (teamMeta.title) {
            tip = `(${tip}) ${teamMeta.title}`;
        }
        tip = translate('label.team.atPosting') + '\n' + tip;
        if (!leader && settings & TeamshipSetting.MANAGE) {
            tip += '\n' + translate('label.team.setting.manage');
        }
        return tip;
    }

    onBtnManageAction(action: string): void {
        let title = translate('forum.userAction.' + action);
        let message = '';
        let setState = DISCUSS_STATE.getByCode(action);
        if (setState) {
            message = setState.getDescription(DISCUSS_CATEGORY.USER_BOARD.code);
        } else if (action == 'markTop') {
            title = translate('forum.btn.to_markTop');
            message = translate('forum.boardTopDesc');
        } else if (action == 'cancelTop') {
            title = translate('forum.btn.to_cancelTop');
            message = translate('forum.boardRemoveTopDesc');
        }
        dialogService.alert(
            {
                titleIcon: this.manageIcons[action],
                title: title,
                body: message,
            },
            {
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller: AlertDialogController) => {
                    let promise: Promise<any> | null = null;
                    if (action == DISCUSS_STATE.CLOSE.code) {
                        promise = serverService.discuss.closeArticle(this.article, '');
                    } else if (action == DISCUSS_STATE.REMOVED.code) {
                        promise = serverService.discuss.removeArticle(this.article);
                    } else if (action == DISCUSS_STATE.OPEN.code) {
                        promise = serverService.discuss.openArticle(this.article, '');
                    } else if (action == DISCUSS_STATE.DIALOG.code) {
                        promise = serverService.discuss.openArticle(this.article, '', DISCUSS_STATE.DIALOG);
                    } else if (action == 'markTop') {
                        promise = serverService.discuss
                            .setArticleTop(this.article, DISCUSS_TOP_MOVEMENT.TOP)
                            .then(() => this.refresh());
                    } else if (action == 'cancelTop') {
                        promise = serverService.discuss
                            .setArticleTop(this.article, DISCUSS_TOP_MOVEMENT.REMOVE)
                            .then(() => this.refresh());
                    }

                    if (promise) {
                        controller.showLoading();
                        promise
                            .then((article) => {
                                this.stateChange(article);
                            })
                            .catch((err) => {
                                dialogService.error({
                                    body: errorToString(err),
                                });
                            })
                            .finally(() => controller.dismiss());
                    }
                },
            }
        );
    }
}
